<template>
  <div>
    <ik-data-table
      :entity-name="$t('unit')"
      :model="model"
    />
  </div>
</template>

<script>
  import unit from '@/model/storage/Unit'
  import { IkDataTable } from 'metaflow-js'

  export default {
    name: 'UnitPage',
    components: {
      IkDataTable,
    },
    data: function () {
      return {
        model: unit,
      }
    },
  }
</script>

<style scoped>

</style>
